import axios from 'axios'
import { Message } from 'element-ui'

import login from '@/components/LoginForm/index.js'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 5 * 60 * 1000, // request timeout
  headers: {
    'Content-Type': 'application/json'
  }
})
// request interceptor
service.interceptors.request.use(
  config => {
    // 注入时间戳和当前用户ID
    if (sessionStorage.getItem('user')) {
      const { userId } = JSON.parse(sessionStorage.getItem('user'))
      if (config.params) {
        // if (!config.params.clinetId) {
        config.params.clinetId = userId
        // }
        if (!config.params._) {
          config.params._ = new Date().getTime()
        }
      } else {
        config.params = { clinetId: userId, _: new Date().getTime() }
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    // const token = getCookie('JSESSIONID')
    if (response.status >= 200 && response.status <= 299) {
      return response.data
    } else {
      Message({
        message: response?.errorMsg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
    }
  },
  error => {
    const { data, status } = error.response
    if (status === 401 || status === 405) {
      // sessionStorage.clear()
      login({
        success: function() {
          window.location.reload()
        }
      })
    } else {
      Message({
        message: data?.errorMsg || '系统出错,请与管理员联系!!',
        type: 'error',
        duration: 5 * 1000
      })
    }

    return Promise.reject(error)
  }
)

export default service
